import React, {Fragment} from "react";
import { Link } from "gatsby";
import ContactForm from '../components/ContactForm';
import Modal from '../components/common/Modal';
import LeftChevronIcon from '../images/icons/arrow-left.png';
import SEO from "../components/seo";

const ContactUs = () => {
    const handleClose = (closeBtn = null) => {
        document.documentElement.classList.remove("pw-no-scroll");
        if (closeBtn) {
            window.history.back();
        }
    }

    return (
        <Fragment>
        <SEO title="Contact Us" />
        <Modal
            openCls="w3-show"
            customClass="w3-animate-top"
            customStyle={{
                width:'100%',
                minHeight:'100vh',
                padding:0,
                margin:0
            }}
            modalStyle={{
                padding:0,
                margin:0
            }}
        >
            <div className="w3-display-container w3-hide-small">
                <div className="pw-modal-cross" onClick={()=>handleClose('cross')}/>
            </div>
            <div className="w3-row pw-contact-us-page w3-hide-medium w3-hide-large">
                <Link to="/" className="pw-contact-us-back-to" onClick={()=>handleClose()}>
                    <img src={LeftChevronIcon} className="pw-contact-us-back-icon" />
                    <div>
                        Back to Homepage
                    </div>
                </Link>
            </div>
            <ContactForm />
        </Modal>
        </Fragment>
    )
}

export default ContactUs;