import React, { useState, useEffect, useContext } from "react";
import { Link } from "gatsby"
import './contact-us.scss';
import DropDown from '../common/DropDown';
import ThankYou from './ThankYou';
import DidYouKnowModal from '../../components/common/DidYouKnowModal'
import tracker from '../../../utils/tracker';
import UserContext from '../../context/UserContext';

const GATSBY_CMS_SERVER = process.env.GATSBY_CMS_SERVER;
const GATSBY_CMS_CONTACT_FORM_ID = process.env.GATSBY_CMS_CONTACT_FORM_ID;
const GATSBY_ENVIRONMENT = process.env.GATSBY_ENVIRONMENT;

const ContactForm = () => {
    const initialData = {
        typeOfEnquiry: '',
        name: '',
        mobileNo: '',
        email: '',
        message: '',
        acceptTerms: false
    }
    const userData = useContext(UserContext);
    const user = userData && userData.user;
    const [openDykCls, setOpenDykCls] = useState('w3-hide');
    const [showThankYou, setShowThankYou] = useState('w3-hide');
    const [inFlight,setInFlight] = useState(false);
    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const options = [
        { key:"general-enquiry", value: 'General Enquiry' },
        { key:"about-wisejourneys", value: 'About WiseJourneys' },
        { key:"contributing-to-superhonestly", value: 'Contributing to SuperHonestly' },
        { key:"about-a-wisetip", value: 'About a WiseTip' },
        { key:"partnership", value: 'Partnership' },
        { key:"media-enquiry", value: 'Media Enquiry' },
    ];

    const handleErrors = (name) => {
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        let changes = {
            ...formData,
            [name]: value
        };
        if (name === 'acceptTerms') {
            changes = {
                ...formData,
                [name]: !Boolean(formData.acceptTerms)
            }
        }
        setFormData(changes);
        handleErrors(name);
    }

    const handleSelect = (selected) => {
        handleErrors('typeOfEnquiry');
        setFormData({
            ...formData,
            typeOfEnquiry: selected
        });
    }

    const getFormFields = (formData) => {

        if (GATSBY_ENVIRONMENT === 'production') {
            return {
                "1": {
                    "value": formData.name,
                    "id": 1
                },
                "2": {
                    "value": formData.email,
                    "id": 2
                },
                "3": {
                    "value": formData.message,
                    "id": 3
                },
                "4": {
                    "value": "",
                    "id": 4
                },
                "5": {
                    "value": formData.typeOfEnquiry,
                    "id": 5
                },
                "6": {
                    "value": formData.mobileNo,
                    "id": 6
                }
            }
        }

        return {
            "1": {
                "value": formData.name,
                "id": 1
            },
            "2": {
                "value": formData.email,
                "id": 2
            },
            "3": {
                "value": formData.message,
                "id": 3
            },
            "4": {
                "value": "",
                "id": 4
            },
            "11": {
                "value": formData.typeOfEnquiry,
                "id": 11
            },
            "7": {
                "value": formData.mobileNo,
                "id": 7
            }
        }
    }


    const data =
    {
        "id": "1",
        "fields": getFormFields(formData),
        "settings": {
            "public_link": `${GATSBY_CMS_SERVER}/ninja-forms/${GATSBY_CMS_CONTACT_FORM_ID}`,
            "public_link_key": GATSBY_CMS_CONTACT_FORM_ID,
        },
    }

    const validateData = () =>{
        const emailPattern = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let hasErrors = false;
        const skipList = [
            'mobileNo'
        ];
        Object.keys(formData).forEach((key) => {
            const pos = skipList.indexOf(key);
            if (pos < 0) {
                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = (key === 'acceptTerms') ? 'Please tick the above checkbox to proceed.' : 'This field is mandatory';
                }

                if(key === 'email' && formData['email']){
                    if (!emailPattern.test(formData.email)) {
                        hasErrors = true;
                        errors.email = 'Enter a valid email';
                    }
                }
            }
        });

        if (hasErrors) {
            setErrors({...errors});
            return false;
        }
        return formData;
    }
    
    const handleSubmit = () => {
        const validData = validateData();
        if(!validData){
            return;
        }
         
        setInFlight(true);
        
        fetch(`${GATSBY_CMS_SERVER}/wp-json/a12r/v1/nonce?name=ninja_forms_display_nonce`)
        .then(r=>r.json())
        .then(resp => { 
            return fetch(`${GATSBY_CMS_SERVER}/wp-admin/admin-ajax.php`, {
                "headers": {
                    "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                "referrer": `${GATSBY_CMS_SERVER}/ninja-forms/${GATSBY_CMS_CONTACT_FORM_ID}/`,
                "referrerPolicy": "strict-origin-when-cross-origin",
                "body": `action=nf_ajax_submit&security=${resp.nonce}&formData=` + encodeURIComponent(JSON.stringify(data)),
                "method": "POST",
                "mode": "no-cors"
            });
        })
        .then(() => fetch(`${process.env.GATSBY_API_BASE}/api/v1/email-subscription/contact-form`, {
            "headers": {
                'Content-Type': 'application/json'
            },
            "body": JSON.stringify({ email: formData.email }),
            "method": "POST",
        }))
        .then(res => {
            setInFlight(true);
            setShowThankYou('w3-show');
        }).catch(er => {
            setInFlight(false);
            setFormData(initialData);
        });
    }

    return (
        <>
        <div className="w3-content pw-contact-form">
            <div className="w3-row w3-center pw-cf-title">
                Contact us
            </div>
            <div className="w3-row w3-center pw-cf-info">
                Have a question? Just want to say hi?<br />
                Drop us a message here and we’ll get back to you as soon as we can.
            </div>
            <div className="w3-row-padding">
                <div className="w3-col s12 m12">
                    <div className="pw-cf-margin-bottom">
                        <label className="pc-cf-label">Type of Enquiry *</label>
                        <DropDown
                            placeholder="Type your answer here…"
                            options={options}
                            customCls="pw-cf-input"
                            selectedValue={formData.typeOfEnquiry}
                            onChange={handleSelect}
                        />
                        <div className="pw-error-text">{errors.typeOfEnquiry}</div>
                    </div>
                </div>
                <div className="w3-col s12 m6">
                    <div className="pw-cf-margin-bottom">
                        <label className="pc-cf-label">Name *</label>
                        <input className="w3-input pw-cf-input"
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="pw-error-text">{errors.name}</div>
                    </div>
                </div>
                <div className="w3-col s12 m6">
                    <div className="pw-cf-margin-bottom">
                        <label className="pc-cf-label">Mobile Number</label>
                        <input className="w3-input pw-cf-input"
                            type="text"
                            name="mobileNo"
                            value={formData.mobileNo}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="pw-error-text">{errors.mobileNo}</div>
                    </div>
                </div>
                <div className="w3-col s12 m12">
                    <div className="pw-cf-margin-bottom">
                        <label className="pc-cf-label">Email *</label>
                        <input className="w3-input pw-cf-input"
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="pw-error-text">{errors.email}</div>
                    </div>
                </div>
                <div className="w3-col s12 m12">
                    <div className="pw-cf-msg-section">
                        <label className="pc-cf-label">Message *</label>
                        <textarea className="w3-input pw-cf-input pw-cf-textarea"
                            rows="6"
                            cols="50"
                            name="message"
                            value={formData.message}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="pw-error-text">{errors.message}</div>
                    </div>
                </div>

                <div className="w3-col s12 m12">
                    <div className="pw-cf-margin-bottom">
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            value={formData.acceptTerms}
                            onChange={e => handleChange(e)}
                            className="pw-cf-margin-right"
                        />
                        <label className="pc-cf-label">By submitting, you are accepting the <Link to="/privacy-policy">privacy policy</Link> of ParentWise.</label>
                        <div className="pw-error-text">{errors.acceptTerms}</div>
                    </div>
                </div>

                <div className="w3-col s12 m12 pw-cf-submit-section">
                    <button className="w3-round-xxlarge w3-button pw-cf-submit-btn" disabled={inFlight} onClick={handleSubmit}>
                        <div className="pw-cf-sub-btn-text">
                            Submit <span className="pw-cf-sub-btn-arrow" />
                        </div>
                    </button>
                </div>
            </div>
            {/* {!user && 
                <div className="dyk-icon">
                    <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/dyk-modal-icon.gif" 
                        className="dyk-modal w3-right w3-margin-right"
                        onClick={() => {
                            setOpenDykCls('w3-show');
                            tracker.trackEvent('Did you know (Pop-up)', 'Click', tracker.getPageLabel());
                        }}
                    />
                    <DidYouKnowModal
                        openCls={openDykCls}
                        setOpenCls={setOpenDykCls}
                    />
                </div>
            } */}
        </div>
        {inFlight == true && showThankYou == "w3-hide" ? 
            <div className="w3-modal pw-loader-main">
                <div className="pw-loader pw-size"></div>
            </div> 
            : <ThankYou
                openCls={showThankYou}
                closeHandler={() => {
                    setShowThankYou('w3-hide');
                    setInFlight(false);
                    setFormData(initialData);
                }}
                description={<div>We’ve received your message<br /> and will get back to you soon!</div>}
                link="/"
            />
        }
        </>

    )
}

export default ContactForm;