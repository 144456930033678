import React,{useState} from 'react';
import './drop-down.scss';

const DropDown = (props) => {
    const {label,placeholder,options, customCls,labelCls,onChange,selectedValue,emoji,containerCls,optionCls,valueCls} = props;
    const [values, setValues] = useState([])
    const [focusedValue, setFocusedValue] = useState(-1);
    const [isOpen, setIsOpen] = useState(false);

    const onClick = () => {
        setIsOpen(!isOpen);
    };

    const onHoverOption = e => {
        const { value } = e.currentTarget.dataset;
        const index = options.findIndex(option => option.value === value);
        setFocusedValue(index);
    };

    const onClickOption = e => {
        const { value } = e.currentTarget.dataset;
            setValues([value]);
            onChange(value);
            setIsOpen(false);
    };

    const renderValues = () => {
        if (!selectedValue) {
            return <div className="placeholder">{placeholder}</div>;
        }
        if (emoji == true ) {
            return <img className="value emoji" src={selectedValue} />
        } else {
            return <div className={`value ${valueCls}`}>{selectedValue}</div>;
        }
    };

    const renderOptions = () => {
        if (!isOpen) {
            return null;
        }
        return <div className={`${containerCls ?  containerCls : "option-container"}`}>{options.map(renderOption)}</div>;
    };

    const renderOption = (option, index) => {
        const selected = values.includes(option.value);
        let className = `${optionCls ?  optionCls : "option"}`;
        if (selected) className += ' selected';
        if (index === focusedValue) className += ' focused';
        return <>
            {emoji ?
                <img 
                    key={option.value}
                    src={option.value}
                    className={className}
                    onMouseOver={onHoverOption}
                    onClick={onClickOption} 
                    data-value={option.value}
                />
            :   
                <div
                    key={option.value}
                    data-value={option.value}
                    className={className}
                    onMouseOver={onHoverOption}
                    onClick={onClickOption}
                >
                    {option.value}
                </div>
            }
        </>
    };

    return (
        <>
            <div className={`w3-input pw-dropdown ${customCls ? customCls:''}`}>
                {label && <label className={`label ${labelCls ? labelCls:''}`}>{label}</label>}
                <div className="selection" onClick={onClick}>
                    {renderValues()}
                    <span className="down-chevron" />
                </div>
                {renderOptions()}
            </div>
        </>
    );
};

export default DropDown;