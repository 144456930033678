import React from 'react';
import { Link } from "gatsby"
import Modal from '../common/Modal';
import './contact-us.scss';


const ThankYou = (props) => {
    const { openCls, closeHandler, description, link } = props;
    
    const handleClose = () => {
        document.documentElement.classList.remove("pw-no-scroll");
        closeHandler();
    }

    return (
        <Modal openCls={openCls}
            customStyle={{ maxWidth: '860px', height: '350px' }}
            customClass="pw-thank-modal"
        >
            <div className="w3-display-container">
                <div
                    className="pw-tm-close"
                    onClick={() => handleClose()}
                />
            </div>
            <div className="w3-content pw-tm-container">
                <div className="pw-tm-main-content">
                    <div className="pw-tm-title">
                        Thank you!
                    </div>
                    <div className="pw-tm-desc">
                        {description}
                    </div>

                    <div className="w3-row pw-tm-continue">
                        <Link to={link}>
                            <div className="w3-round-xxlarge pw-tm-continue-btn">
                                <div className="pw-tm-btn-text">
                                    Continue browsing <span className="pw-tm-btn-arrow" />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

        </Modal>
    )

}
export default ThankYou